import React, { useState, useLayoutEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { Spinner, Form, Button } from "react-bootstrap";
import LoginService from "../services/LoginService";
import { InfoMsg, keyPress } from "../helpers";

const Login = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const findUsername = () => {
    if (!username || !pwd) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    setProcessing(processing + "1");
    LoginService.findUsername(username, pwd)
      .then((response) => {
        if (response.data && response.data.utilisateur) {
          initcto(response.data.message, "info", "1", response.data);
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  };

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (p && p.utilisateur) {
      localStorage.setItem('outi', JSON.stringify(p.utilisateur)); window.dispatchEvent(new Event('storage'));
      localStorage.setItem('lastaction', Date.now());
      loggeduser.current = p.utilisateur;
    }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    loggeduser.current && loggeduser.current.cid ? <Navigate to={{ pathname: '/' + (loggeduser.current.cpage || 'resultatsvague') }} /> : <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="dfaic">Page d'identification</h2><hr /></div>
      <Form className="col-lg-6" autoComplete="off">
        <Form.Group className="mb-3 dfac fdc" controlId="inpt_1">
          <Form.Label>Identifiant *</Form.Label>
          <Form.Control type="text" className="mw100 center" value={username} onChange={(event) => setUsername(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" autoFocus={!window.isMobile} />
        </Form.Group>
        <Form.Group className="mb-3 dfac fdc" controlId="inpt_2">
          <Form.Label>Mot de passe *</Form.Label>
          <Form.Control type="text" className="mw100 center picls" value={pwd} onChange={(event) => setPwd(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" />
        </Form.Group>
        <Form.Group className="mb-3 mt-5 dfac fdc">
          <Button ref={ref => inpt.current[0] = ref} variant="primary" type="button" className="w100" onClick={findUsername} disabled={!username || !pwd || processing.indexOf("1") != -1}>
            {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Connexion en cours...</span> : "Connexion"}
          </Button>
        </Form.Group>
        <Form.Group className="mb-3 mt-5 dfac fdc">
          <img src={require('../images/mv2clientstrategy.png')} alt="SNCF-MV2" className="bimg" style={{ width: "25vw" }} />
        </Form.Group>
      </Form>
    </div>
  );
};

export default Login;
