import { useState, useLayoutEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import LogoutService from "../services/LogoutService";
import { InfoMsg } from "../helpers";

const Logout = (props) => {
  const ito = useRef();
  const { msg } = useParams(), navigate = useNavigate();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  const clear = () => {
    setProcessing(processing + "-5");
    LogoutService.logout()
      .then((response) => {
        localStorage.clear();
        initcto(response.data.message, "info", "-5");
        navigate("/" + (msg || 1), { replace: true });
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Impossible de vous déconnecter ! réessayer.", "danger", "-5");
      });
  }

  useLayoutEffect(() => {
    //On_Mount
    clear();

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} minit="Redirection... Veuillez patienter." />
      <Link to="/">Page d'accueil</Link>
    </div>
  );
};

export default Logout;
