import React, { useState, useLayoutEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { BsPersonPlusFill, BsXCircle } from "react-icons/bs";
import { Spinner, Form, Button, Modal } from "react-bootstrap";
import ProfilService from "../services/ProfilService";
import { InfoMsg, keyPress } from "../helpers";

const Profil = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [modalv, setModalv] = useState([]);

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const [updt, setUpdt] = useState(true);
  const [cid, setCid] = useState(loggeduser.current.cid || "");
  const [cutype, setCutype] = useState(loggeduser.current.cutype || "");
  const [cusername, setCusername] = useState(loggeduser.current.cusername || "");
  const [cpwd, setCpwd] = useState("");
  const [confpwd, setConfpwd] = useState("");
  const [cfname, setCfname] = useState(loggeduser.current.cfname || "");
  const [clname, setClname] = useState(loggeduser.current.clname || "");
  const [cemail, setCemail] = useState(loggeduser.current.cemail || "");
  const [cpage, setCpage] = useState(loggeduser.current.cpage || "");

  const updateUser = () => {
    if (!cutype || !cusername || (!cid && !cpwd) || (cpwd && !confpwd)) {
      initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
      return;
    }

    if (cpwd) {
      if (cpwd && !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/).test(cpwd)) {
        initcto("Le mot de passe doit comporter 8 caractères ou plus. Il doit contenir au moins un caractère spécial, au moins 1 chiffre, au moins 1 lettre majuscule et au moins 1 lettre minuscule. Il ne peut pas contenir d'espaces !", "danger");
        return;
      }

      if (confpwd != cpwd) {
        initcto("Le mot de passe et la confirmation du mot de passe doivent correspondre !", "danger");
        return;
      }
    }

    if (cemail && !(/\w+([\-\+\.]\w+)*@\w+([\-\.]\w+)*\.\w+([\-\.]\w+)*/).test(cemail)) {
      initcto("Veuillez saisir une adresse e-mail valide !", "danger");
      return;
    }

    setProcessing(processing + "1");
    ProfilService.updateUser(Object.assign({ cutype, cusername, cfname, clname, cemail, cpage }, cpwd ? { cpwd } : null, cid ? { cid } : null))
      .then((response) => {
        if (response.data && response.data.utilisateur) {
          initcto(response.data.message, "info", "1", response.data);
        }
        else {
          initcto("Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
        }
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "1");
      });
  };

  const reinit = (upd) => {
    var usr = upd ? loggeduser.current : {};
    setUpdt(upd);
    setCid(usr.cid || "");
    setCutype(usr.cutype || "");
    setCusername(usr.cusername || "");
    setCpwd("");
    setConfpwd("");
    setCfname(usr.cfname || "");
    setClname(usr.clname || "");
    setCemail(usr.cemail || "");
    setCpage(usr.cpage || "");
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
    if (p && p.utilisateur) {
      if (cid) {
        p.utilisateur = Object.assign(loggeduser.current, p.utilisateur);
        localStorage.setItem('outi', JSON.stringify(p.utilisateur)); window.dispatchEvent(new Event('storage'));
        loggeduser.current = p.utilisateur;
      }
    }
  }

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    loggeduser.current && loggeduser.current.cid ? <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="dfaic">{updt ? <>Profil Utilisateur{loggeduser.current.cutype == 1 ? <> &nbsp; &nbsp;<Button variant="primary" type="button" title="Créer un nouvel utilisateur" onClick={() => reinit(false)}><BsPersonPlusFill className='opl gicon' /></Button></> : null}</> : <>Nouvel Utilisateur &nbsp; &nbsp;<Button variant="danger" type="button" title="Annuler et fermer" onClick={() => reinit(true)}><BsXCircle className='opl gicon' /></Button></>}</h2><hr /></div>
      <Form className="col-lg-6" autoComplete="off">
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_0">
          <Form.Label className="smlbl">Type Utilisateur *</Form.Label>
          <Form.Select className="mw100 center" value={cutype + "," + cpage} onChange={(event) => { let v = event.target.value.split(","); setCutype(v[0]); setCpage(v[1]); }} disabled={updt}>
            <option value="">Veuillez sélectionner</option>
            {[[1, "admin", "Admin"], [2, "resultatsvague", "Utilisateur"]].map((val, idx) => <option key={idx} title={val[2]} value={val[0] + "," + val[1]}>{val[2]}</option>)}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_1">
          <Form.Label className="smlbl">Identifiant *</Form.Label>
          <Form.Control type="text" className="mw100 center" value={cusername} onChange={(event) => setCusername(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} disabled={updt} />
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_2">
          <Form.Label className="smlbl">Mot de passe *</Form.Label>
          <Form.Control type="text" className="mw100 center picls" value={cpwd} onChange={(event) => setCpwd(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} />
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_3">
          <Form.Label className="smlbl">Confirmer Mot de passe *</Form.Label>
          <Form.Control type="text" className="mw100 center picls" value={confpwd} onChange={(event) => setConfpwd(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={20} />
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_4">
          <Form.Label className="smlbl">Prénom</Form.Label>
          <Form.Control type="text" className="mw100 center" value={cfname} onChange={(event) => setCfname(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" maxLength={50} />
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_5">
          <Form.Label className="smlbl">Nom</Form.Label>
          <Form.Control type="text" className="mw100 center" value={clname} onChange={(event) => setClname(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" maxLength={50} />
        </Form.Group>
        <Form.Group className="mb-2 dfac fdc" controlId="inpt_6">
          <Form.Label className="smlbl">Adresse e-mail</Form.Label>
          <Form.Control type="text" className="mw100 center" value={cemail} onChange={(event) => setCemail(event.target.value)} onKeyPress={(e) => keyPress(e, () => inpt.current[0].click())} autoComplete="off" autoCorrect="off" autoCapitalize="none" maxLength={50} />
        </Form.Group>
        <Form.Group className="mb-2 mt-4 dfac fdr">
          <Button ref={ref => inpt.current[0] = ref} variant="primary" type="button" style={{ flex: 1 }} onClick={updateUser} disabled={processing.indexOf("1") != -1}>
            {processing.indexOf("1") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; Traitement en cours...</span> : "Soumettre"}
          </Button>
          <Button variant="warning" type="button" style={{ flex: 1 }} onClick={() => reinit(updt)} disabled={processing.indexOf("1") != -1}>
            Réinitialiser
          </Button>
        </Form.Group>
      </Form>
      <Modal show={modalv[0]} onHide={modalv[1]} size="lg" centered>
        <Modal.Header closeButton><Modal.Title>{modalv[2]}</Modal.Title></Modal.Header>
        <Modal.Body>{modalv[3]}</Modal.Body>
        <Modal.Footer>{modalv[4]}</Modal.Footer>
      </Modal>
    </div> : <Navigate to={{ pathname: '/logout/3' }} />
  );
};

export default Profil;
