import http from "../http";

const upload = (props, files, p, c, data, onUploadProgress) => {
    var body = new FormData(), name = [], size = 0, arrlen = files.length, lm1 = arrlen - 1, reqs = [];
    Object.keys(data).map((val, idx) => { if (data[val]) body.append(val, data[val]); });
    for (let i = 0; i < arrlen; i++) {
        body.append("file", files[i]);
        name.push(files[i].name);
        size += files[i].size;
        if (size > 51380224 || i == lm1) {
            reqs.push(http.post("/upload/" + props.folder + "?accept=" + (props.accept || "") + "&name=" + name.join("|||") + "&p=" + p + "&c=" + (c || ""), body, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress }));
            body = new FormData(); name = []; size = 0; Object.keys(data).map((val, idx) => { if (data[val]) body.append(val, data[val]); });
        }
    }
    return Promise.allSettled(reqs);
};

const unupload = (props, data) => {
    return http.delete("/files/" + props.folder + "/" + (data.file.cnomfichier || "") + "?path=" + (data.file.cpathonserver || ""));
};

const getlist = (props, t) => {
    return http.get("/files/" + props.folder + "?accept=" + (props.accept || "") + "&t=" + (t || ""));
};

const cleandata = (props) => {
    return http.post("/clean/" + props.folder + "?accept=" + (props.accept || ""));
};

const update = (id, data) => {
    return http.put("/files/" + id, data);
};

const zip = (folder, data) => {
    return http.post("/zip/" + folder, data, { responseType: 'arraybuffer' });
};

export default {
    upload,
    unupload,
    getlist,
    cleandata,
    update,
    zip
};
