import React, { useState, useLayoutEffect, useRef } from "react";
import { BsTrash } from "react-icons/bs";
import { Spinner, Form, Button } from "react-bootstrap";
import FileUploadService from "../services/FileUploadService";
import FormInputs from "./FormInputs";

const UploadFiles = (props) => {
    const inpt = useRef([]), folder = props.folder || "datafiles", scrn = props.scrn || "a";
    const [progress, setProgress] = useState(0);
    const [selectedfiles, setSelectedfiles] = useState();
    const [selectedfile, setSelectedfile] = useState();
    const [files, setFiles] = useState([]);
    const [deltfile, setDeltfile] = useState({});
    const inpts = props.inpts || {};
    const [inputs, setInputs] = useState(Object.fromEntries(Object.entries(inpts).map(([key, val]) => [key, val[1]])));

    const initcto = (m, t, a, p, c) => {
        props.initcto(m, t);
        if (a) props.setProcessing(props.processing.replace(a, ""));
        if (p) {
            if (p.cid) {
                setDeltfile({ ...deltfile, [p.cid]: null });
            }
        }
        else {
            setProgress(0);
            inpt.current[0]?.setinitial(undefined, 1);
            if (p === 0) {
                setSelectedfiles();
                inpt.current[2].value = "";
            }
            else {
                setSelectedfile();
                inpt.current[1].value = "";
            }
        }
        //In MySQL: SHOW GLOBAL VARIABLES LIKE '%innodb_old_blocks_time%'; SET GLOBAL innodb_old_blocks_time=0;
        if (c) getlist(1);
    }

    const upload = (sf, p, c) => {
        if (!sf.length) {
            initcto("Veuillez charger un fichier !", "danger");
            return;
        }

        var alrt = false, arr = Object.keys(inpts), arrlen = arr.length;
        for (let i = 0; i < arrlen; i++) { if (inpts[arr[i]][0].slice(-2) == " *" && !inputs[arr[i]]) { alrt = true; break; } }
        if (alrt) {
            props.initcto("Veuillez saisir une valeur pour chacun des champs de saisie marqués d'un '*' !", "danger");
            return;
        }

        props.setProcessing(props.processing + "-1");
        if (!c) {
            setProgress(0);
        }

        FileUploadService.upload({ ...props }, sf, p, c, inputs, (event) => setProgress(Math.round((100 * event.loaded) / event.total)))
            .then((parr) => {
                parr.map((val, idx) => {
                    if (val.status == "fulfilled") {
                        initcto(val.value.data.message, "info", "-1", p, 1000);
                    }
                    else {
                        let cb = () => initcto(val.reason.response.data ? val.reason.response.data.message : "Impossible de charger le(s) fichier(s) " + (sf.length == 1 ? ": " + sf[0].name : "du répertoire choisi") + " !", "danger", "-1", p);
                        if (val.reason.response.status == 400) {
                            return props.setModalv([true, () => { props.setModalv([]); cb(); }, "Alerte", "Un ou plusieurs fichiers avec le(s) même(s) nom(s) existent déjà sur le serveur. Voulez-vous continuer en le(s) remplacant avec le(s) nouveau(x) fichier(s) chargé(s) ?\nCela implique aussi le remplacement des données dans la base.", [<Button key="0" variant="secondary" onClick={() => { props.setModalv([]); cb(); }}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); upload(sf, p, 1); }}>Confirmer</Button>]]);
                        }
                        cb();
                    }
                });
            });
    }

    const unupload = (file) => {
        props.setProcessing(props.processing + "-2");
        setDeltfile({ ...deltfile, [file.cid]: 1 });

        FileUploadService.unupload(props, { file })
            .then((response) => {
                initcto(response.data.message, "info", "-2", file, 1000);
            })
            .catch((err) => {
                initcto(err.response.data ? err.response.data.message : "Impossible de supprimer le fichier : " + file.cnomfichier, "danger", "-2", file);
            });
    }

    const getlist = (t) => {
        if (!t) props.setProcessing(props.processing + "-4");
        FileUploadService.getlist(props, t).then((response) => {
            localStorage.setItem("url" + folder, response.data.url);
            setFiles(response.data.files);
            props.setProcessing(props.processing.replace("-4", ""));
            if (t) {
                localStorage.removeItem('idata' + folder + "v");
                if (folder == "datafiles") {
                    ["cloudfiles", "evolfiles", "classfiles"].map((val, idx) => localStorage.removeItem('idata' + val + "v"));
                }
            }
        }).catch((err) => { props.setProcessing(props.processing.replace("-4", "")); });
    }

    const cleandata = () => {
        props.setProcessing(props.processing + "-3");

        FileUploadService.cleandata(props)
            .then((response) => {
                initcto(response.data.message, "info", "-3", {}, 1000);
            })
            .catch((err) => {
                initcto(err.response.data ? err.response.data.message : "Impossible de vider la base de données ! Veuillez réessayer plus tard.", "danger", "-3", {});
            });
    }

    useLayoutEffect(() => {
        //On_Mount
        getlist();

        //On_Unmount
        //return () => { }
    }, []);

    return (
        <div className="form-group">
            <div className="dfac mt-1 mb-1">
                <Button
                    className="btn btn-danger"
                    disabled={props.processing.indexOf("-3") != -1 || !files || !files.length}
                    onClick={(e) => { e.currentTarget.blur(); props.setModalv([true, () => props.setModalv([]), "Alerte", "Êtes-vous sûr de bien vouloir supprimer tous les fichiers correspondant à cette section, et toutes leurs données ?", [<Button key="0" variant="secondary" onClick={() => props.setModalv([])}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); cleandata(); }}>Confirmer</Button>]]); }}
                    title="Supprimer tous les fichiers correspondant à cette section, et toutes leurs données."
                >{props.processing.indexOf("-3") != -1 ? <span><Spinner animation="border" size="sm" /> &nbsp; </span> : null}
                    Vider Base de Données
                </Button>
                {props.ext && props.ext.uploaddir && (
                    <div className="fdr"><label><Form.Control type="file" ref={ref => inpt.current[2] = ref} disabled={props.processing.indexOf("-1") != -1} onChange={(event) => setSelectedfiles(event.target.files)} accept={props.accept} title="Sélectionner un ou plusieurs fichiers." multiple /*directory="" webkitdirectory=""*/ />
                    </label>
                        &nbsp;
                        <Button
                            className="btn btn-success"
                            disabled={!selectedfiles || props.processing.indexOf("-1") != -1}
                            onClick={(e) => { e.currentTarget.blur(); upload(selectedfiles, 0); }}
                            title="Charger certains ou tous les fichiers d'un répertoire."
                        >
                            {props.processing.indexOf("-1") != -1 && progress < 100 ? <span><Spinner animation="border" size="sm" /> &nbsp; Chargement en cours...</span> : "Charger Répertoire"}
                        </Button>
                    </div>
                )}
            </div>
            {inpts ? <FormInputs ref={ref => inpt.current[0] = ref} setProcessing={props.setProcessing} initcto={props.initcto} setInputs={setInputs} inputs={inputs} inpts={inpts} reinit={props.reinit} folder={folder} scrn={scrn} processing={props.processing} /> : null}
            <div className="dfac mt-3" style={{ marginBottom: 2 }}>
                {props.processing.indexOf("-1") != -1 && (
                    <div className="progress w100">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
                <label style={{ flex: 1 }}>
                    <Form.Control type="file" ref={ref => inpt.current[1] = ref} className="mw100" disabled={props.processing.indexOf("-1") != -1} onChange={(event) => setSelectedfile(event.target.files)} accept={props.accept} title="Sélectionner un fichier." />
                </label>
                &nbsp;
                <Button
                    className="btn btn-info"
                    disabled={!selectedfile || props.processing.indexOf("-1") != -1}
                    onClick={(e) => { e.currentTarget.blur(); upload(selectedfile, ""); }}
                    title={props.title}
                >
                    {props.processing.indexOf("-1") != -1 && progress < 100 ? <span><Spinner animation="border" size="sm" /> &nbsp; Chargement en cours...</span> : props.label || "Charger Fichier"}
                </Button>
            </div>

            <div className="card mb-1">
                <div className="card-header">Liste de fichiers</div>
                <ul className="list-group list-group-flush">
                    {files && files.length ? files.map((file, index) => (
                        <li className="list-group-item meds" key={index}>
                            <span className='textred cp'>{deltfile[file.cid] ? <Spinner animation="border" size="sm"></Spinner> : <BsTrash onClick={() => props.setModalv([true, () => props.setModalv([]), "Alerte", "Êtes-vous sûr de bien vouloir supprimer le fichier : " + file.cnomfichier + " ?", [<Button key="0" variant="secondary" onClick={() => props.setModalv([])}>Annuler</Button>, <Button key="1" variant="primary" onClick={() => { props.setModalv([]); unupload(file); }}>Confirmer</Button>]])} />} &nbsp; </span><a href={localStorage.getItem("url" + folder) + file.cnomfichier} target="_blank" /*rel="noopener noreferrer"*/>{file.cnomfichier.match(/.(jpg|jpeg|png|gif)$/i) ? <img src={file.cid} alt={file.cnomfichier} title={file.cnomfichier} /> : file.cnomfichier}</a>
                        </li>)) : <li className="list-group-item" style={{ padding: 0 }} key={0}><div className="alert alert-warning meds">Aucune donnée trouvée.</div></li>}
                </ul>
            </div>
        </div>
    );
};

export default UploadFiles;
