import React, { useState, useLayoutEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { Tabs, Tab, Modal } from "react-bootstrap";
//import AdminService from "../services/AdminService";
import FileUpload from "./FileUpload";
import { InfoMsg } from "../helpers";

const Admin = (props) => {
  const ito = useRef(), loggeduser = useRef();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [modalv, setModalv] = useState([]);

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  var tabs = [["Fichiers de données", "datafiles", ".xls,.xlsx", undefined, undefined], ["Fichiers de nuages de mots", "cloudfiles", ".pdf,.ppt,.pptx", { canneevague: ["Année", "", 1, undefined, {}], ctypevague: ["Type Vague", "", 1, undefined, {}], cnomvague: ["Nom Vague", "", 1, undefined, {}], ctheme: ["Thème", "", 1, undefined, {}], ctypesite: ["Type Site", "", 1, undefined, {}], cnomsite: ["Nom Site", "", 1, undefined, {}], cfrequence: ["Fréquence", "", 1, undefined, {}] }, undefined, { uploaddir: 1 }], ["Fichiers de documentations", "docfiles", ".pdf,.ppt,.pptx,.htm,.html,.txt,.doc,.docx", { ctitrefichier: ["Titre *", "", 1, undefined, { nosrch: 1 }], cdetailfichier: ["Description", "", 1, undefined, { nosrch: 1 }] }, undefined]], tabsl = tabs.length, tabsc = [];
  for (let i = 0; i < tabsl; i++) {
    tabsc.push(<Tab key={i} eventKey={tabs[i][1]} title={tabs[i][0]}><FileUpload key={i} label="Charger Fichier" folder={tabs[i][1]} accept={tabs[i][2]} setModalv={setModalv} initcto={initcto} setProcessing={setProcessing} inpts={tabs[i][3]} reinit={tabs[i][4]} processing={processing} ext={tabs[i][5]} /></Tab>);
  }

  return (
    loggeduser.current && loggeduser.current.cid && loggeduser.current.cutype == 1 ? <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-4", "-5"]} aproc={["-"]} />
      <div className="col-md-12 mt-1">
        <Tabs defaultActiveKey={tabs[0][1]} mountOnEnter={true} id="uploadfiles" className="mb-2">{tabsc}</Tabs>
      </div>
      <Modal show={modalv[0]} onHide={modalv[1]} size="lg" centered>
        <Modal.Header closeButton><Modal.Title>{modalv[2]}</Modal.Title></Modal.Header>
        <Modal.Body>{modalv[3]}</Modal.Body>
        <Modal.Footer>{modalv[4]}</Modal.Footer>
      </Modal>
    </div> : <Navigate to={{ pathname: '/logout/3' }} />
  );
};

export default Admin;
