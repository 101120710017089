import React, { useState, useLayoutEffect, useRef } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { HttpInterceptors } from './HttpInterceptors';
import { BsFillPieChartFill, BsSortDown, BsPower, BsChatLeftDotsFill, BsSoundwave, BsGraphUp, BsAt, BsBookHalf, BsFillPersonBadgeFill, BsBoxArrowInRight, BsHammer } from "react-icons/bs"; //BsStackOverflow,BsBarChartSteps,BsGraphUp
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Index from "./components/Index";
import Login from "./components/Login";
import Logout from "./components/Logout";
import ResultatsNuage from "./components/ResultatsNuage";
import ResultatsVague from "./components/ResultatsVague";
import ResultatsEvolution from "./components/ResultatsEvolution";
import Classement from "./components/Classement";
import Docs from "./components/Docs";
import Contact from "./components/Contact";
import Admin from "./components/Admin";
import Profil from "./components/Profil";

const App = () => {
  const ito = useRef(), loggeduser = useRef();
  const location = useLocation(), navigate = useNavigate();
  let leftnav = [], rightnav = [];

  const getuser = () => {
    let outi = localStorage.getItem('outi');
    loggeduser.current = outi ? JSON.parse(outi) : {};
    return loggeduser.current.cfname || loggeduser.current.cusername;
  }
  const [uname, setUname] = useState(getuser());

  useLayoutEffect(() => {
    //On_Mount
    function changeStorage() {
      setUname(getuser());
    }
    clearInterval(ito.current);
    if (loggeduser.current && loggeduser.current.cid) {
      localStorage.setItem('idletime', 600000); //600000
      const saveaction = (e) => {
        //console.log("saveaction", Date.now());
        localStorage.setItem('lastaction', Date.now());
      }
      const trackidle = () => {
        //console.log("trackidle", loggeduser, Date.now());
        let creqts = parseInt(localStorage.getItem('creqts') || 0);
        if (creqts > 0) {
          localStorage.setItem('lastaction', Date.now());
        } else {
          if (Date.now() - parseInt(localStorage.getItem('lastaction')) > parseInt(localStorage.getItem('idletime'))) {
            //console.log("doidle", Date.now() - parseInt(localStorage.getItem('lastaction')), parseInt(localStorage.getItem('idletime')));
            navigate("/logout/2", { replace: true });
          }
        }
      }
      document.onmousemove = saveaction;
      document.onclick = saveaction;
      document.onkeydown = saveaction;
      document.ontouchstart = saveaction;
      document.ontouchmove = saveaction;
      ito.current = setInterval(trackidle, 60000); //60000
    }
    else {
      document.onmousemove = undefined;
      document.onclick = undefined;
      document.onkeydown = undefined;
      document.ontouchstart = undefined;
      document.ontouchmove = undefined;
    }
    window.addEventListener('storage', changeStorage);
    document.querySelectorAll('.btn.nav-link').forEach((button) => button.classList.remove("nav-link"));
    //document.querySelectorAll('.nav-item.dropdown').forEach((button) => button.blur());
    window.isMobile = window.innerWidth <= 767;

    //On_Unmount
    return () => { clearInterval(ito.current); window.removeEventListener('storage', changeStorage); }
  }, [location]);

  if (loggeduser.current && loggeduser.current.cid) {
    leftnav.push(<NavDropdown key="0" active={location.pathname.indexOf("/resultats") != -1} title={<span><BsFillPieChartFill className='opl' />&nbsp; Résultats</span>} renderMenuOnMount={true} id="collasible-nav-dropdown">
      <NavDropdown.Item as={Link} to="/resultatsnuage" eventKey="/resultatsnuage"><BsChatLeftDotsFill className='opl' />&nbsp; Nuages de mots</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} to="/resultatsvague" eventKey="/resultatsvague"><BsSoundwave className='opl' />&nbsp; Vague</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} to="/resultatsevolution" eventKey="/resultatsevolution"><BsGraphUp className='opl' />&nbsp; Évolution</NavDropdown.Item>
    </NavDropdown>,
      <Nav.Link key="1" as={Link} to="/classement" eventKey="/classement"><BsSortDown className="opl" />&nbsp; Classement</Nav.Link>,
      <Nav.Link key="2" as={Link} to="/docs" eventKey="/docs" title="Documentation"><BsBookHalf className="opl" />&nbsp; Docs</Nav.Link>);
    if (loggeduser.current.cutype == 1) {
      rightnav.push(<Nav.Link key="2" as={Link} to="/admin" eventKey="/admin"><BsHammer className="opl" />&nbsp; Accès Admin</Nav.Link>);
    }
    rightnav.push(<Nav.Link key="0" as={Link} to="/profil" eventKey="/profil" title="Mon Profil"><BsFillPersonBadgeFill className="opl" />&nbsp; Bienvenue&nbsp;<span className="c">{uname}</span></Nav.Link>, <Nav.Link key="1" as={Link} to="/logout" eventKey="/logout" className="btn btn-warning"><BsPower className="opl" />&nbsp; Déconnexion</Nav.Link>);
  }
  else {
    if (location.pathname != "/login") rightnav.push(<Nav.Link key="0" as={Link} to="/login" eventKey="/login" className="btn btn-info"><BsBoxArrowInRight className="opl" />&nbsp; Connexion</Nav.Link>);
  }

  return (
    <div className="App">
      <Navbar collapseOnSelect expand="md" bg="white" variant="light" sticky="top">
        <Navbar.Brand as={Link} to="/" title="Page d'accueil">
          <img src={require('./images/logo.png')} alt="SNCF-MV2" className="simg" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="jcsb">
          <Nav variant="pills" activeKey={location.pathname} defaultActiveKey="/" className="mr-auto">
            {leftnav}
            <Nav.Link as={Link} to="/contact" eventKey="/contact"><BsAt className="opl" />&nbsp; Contact</Nav.Link>
          </Nav>
          <Nav variant="pills" activeKey={location.pathname}>
            {rightnav}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <HttpInterceptors />
      <div className="container mt-3">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/resultatsnuage" element={<ResultatsNuage />} />
          <Route path="/resultatsvague" element={<ResultatsVague />} />
          <Route path="/resultatsevolution" element={<ResultatsEvolution />} />
          <Route path="/classement" element={<Classement />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/logout/:msg" element={<Logout />} />
          <Route index element={<Index />} />
          <Route path=":msg" element={<Index />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
