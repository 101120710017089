import { Text } from "recharts";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Spinner } from "react-bootstrap";
import { BsFileEarmarkArrowDownFill, BsFileEarmarkExcelFill, BsFileEarmarkImageFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkPptFill, BsFillFileEarmarkWordFill, BsFillFileEarmarkCodeFill, BsFileEarmarkZipFill } from "react-icons/bs";

export function InfoMsg(props) {
  var { processing = "", message = [], ainit = [], aproc = [], cinit = [], cproc = [], initc, minit } = props;
  
  return (
    <div className="pa fdr"><div style={{ position: "relative" }}>{ainit.some((v, i) => processing.includes(v) && (cinit[i] === undefined || cinit[i])) ? <div className={"alert alert-info meds"}><span><Spinner animation="border" size="md" /> &nbsp; {minit || "Chargement..."}</span></div> : aproc.some((v, i) => processing.includes(v) && (cproc[i] === undefined || cproc[i])) ? <div className={"alert alert-info meds"}><span><Spinner animation="border" size="md" /> &nbsp; Traitement en cours... Veuillez patienter.</span></div> : message[0] ? <><div className={"alert alert-" + message[1] + " meds"}>{message[0]}</div><span className="close cp cbtn" onClick={initc} title="Fermer">x</span></> : null}</div></div>
  );
}

export function ElipsText(props) {
  var { x, y, payload, maxLines = 3, width, textAnchor, verticalAnchor, fontSize, fontWeight, angle, dx, dy, style, fill, ext = "" } = props;
  
  return (
    <><Text x={x} y={y} dx={dx} dy={dy} width={width} fill={fill} textAnchor={textAnchor} verticalAnchor={verticalAnchor} maxLines={maxLines} fontSize={fontSize} fontWeight={fontWeight} angle={angle} style={style}>{payload.value + ext}</Text><title>{payload.value + ext}</title></>
  );
}

export function keyPress(event, cb) {
  if (event.key === 'Enter') {
    cb();
  }
}

export function gicon(filename) {
  switch (filename.toLocaleLowerCase().split('.').pop()) {
    case 'xls':
    case 'xlsx':
      return <BsFileEarmarkExcelFill className='opl gicon' />;
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
      return <BsFileEarmarkImageFill className='opl gicon' />;
      break;
    case 'pdf':
      return <BsFillFileEarmarkPdfFill className='opl gicon' />;
      break;
    case 'ppt':
    case 'pptx':
      return <BsFillFileEarmarkPptFill className='opl gicon' />;
      break;
    case 'doc':
    case 'docx':
      return <BsFillFileEarmarkWordFill className='opl gicon' />;
      break;
    case 'htm':
    case 'html':
      return <BsFillFileEarmarkCodeFill className='opl gicon' />;
      break;
    case 'zip':
    case 'tar':
      return <BsFileEarmarkZipFill className='opl gicon' />;
      break;
    default:
      return <BsFileEarmarkArrowDownFill className='opl gicon' />;
  }
}

export function exportChart(chrts, etype, cid) {
  let chartSVG = chrts.container, svgURL, svgBlob, width, height, ratio, URL, blobURL, context, opts = { useCORS: true, allowTaint: true, logging: false, onclone: (doc) => { let v0 = doc.querySelectorAll(".recharts-fo"), v1 = v0.length, v2, v3; for (let i = 0; i < v1; i++) { v2 = v0[i].parentNode.parentNode; v2.prepend(v0[i]); v3 = document.createElement('div'); v3.innerHTML = v0[i].innerHTML; v3.style.cssText = "padding:10px 10px 0 10px;margin-bottom:-10px;"; v2.replaceChild(v3, v0[i]); v2.style.height = "auto"; } } };
  switch (etype) {
    case 'svg':
      chartSVG = chartSVG.children[0].cloneNode(true); chartSVG.removeChild(chartSVG.getElementsByTagName("foreignObject")[1]);
      svgBlob = new Blob([new XMLSerializer().serializeToString(chartSVG)], { type: "image/svg+xml;charset=utf-8" });
      saveAs(svgBlob, cid + ".svg");
      break;
    case 'html':
      chartSVG = chartSVG.children[0].cloneNode(true); chartSVG.removeChild(chartSVG.getElementsByTagName("foreignObject")[1]);
      svgBlob = new Blob([chartSVG.outerHTML], { type: "text/html;charset=utf-8" });
      saveAs(svgBlob, cid + ".html");
      break;
    case 'png':
    case 'jpeg':
      html2canvas(chartSVG, opts)
        .then((canvas) => {
          saveAs(canvas.toDataURL('image/' + etype + '', 1.0), cid + "." + etype);
        }).catch((err) => { console.log(err); });
      break;
    case 'pdf':
      context = new jsPDF('p', 'px', 'a4'); svgURL = context.internal.pageSize.getWidth(); svgBlob = context.internal.pageSize.getHeight();
      context.text("Chargement...", svgURL / 2.5, 50); let nw = window.open(context.output("bloburi", cid + "." + etype), cid + "." + etype);
      context = new jsPDF('p', 'px', 'a4'); html2canvas(chartSVG, opts)
        .then((canvas) => {
          URL = svgURL / canvas.width; blobURL = svgBlob / canvas.height; ratio = URL > blobURL ? blobURL : URL;
          width = canvas.width * ratio; height = canvas.height * ratio;
          context.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', (svgURL - width) / 2, (svgBlob - height) / 2, width, height);
          nw.location.replace(context.output("bloburi", cid + "." + etype)); //"bloburi"//"dataurlstring"
          //context.output("dataurlnewwindow", cid + "." + etype); //context.save(cid + "." + etype);
        }).catch((err) => { console.log(err); });
      break;
  }
}
