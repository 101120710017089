import React, { useState, useLayoutEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
//import IndexService from "../services/IndexService";
import { InfoMsg } from "../helpers";

const Index = (props) => {
  const ito = useRef();
  const location = useLocation();
  const { msg } = useParams();
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
  }

  const init = () => {
    /*setProcessing(processing + "-5");
    IndexService.getAll()
      .then((response) => {
        initcto(response.data.message, "info", "-5", response.data);
        console.log(response.data);
      })
      .catch((err) => {
        initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau ! Veuillez réessayer.", "danger", "-5");
        console.log(err);
      });*/
    let marr = ["", ""];
    switch (msg) {
      case "1":
        marr = ["Déconnexion réussie.", "info"];
        break;
      case "2":
        marr = ["Session expirée ! Veuillez vous reconnecter pour continuer.", "info"];
        break;
      case "3":
        marr = ["Vous n'êtes pas autorisé à accéder à cette page !", "danger"];
        break;
      case "401":
        marr = ["Accès Non Autorisé !", "danger"];
        break;
      case "403":
        marr = ["Accès Interdit !", "danger"];
        break;
    }
    setMessage(marr);
  }

  useLayoutEffect(() => {
    //On_Mount
    init();

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, [location]);

  return (
    <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="dfaic">Page d'accueil</h2><hr /></div>
      <div className="mb-3 mt-5"><h4>Bienvenue en Normandie sur le réseau Nomad</h4></div>
      <div className="mb-3 mt-5 dfac fdc">
        <img src={require('../images/sncfmv2logo.png')} alt="SNCF-MV2" className="bimg" />
      </div>
    </div>
  );
};

export default Index;
