import React, { useState, useLayoutEffect, useRef, useCallback, useMemo, forwardRef } from "react";
import { Navigate } from "react-router-dom";
import { BsBookHalf, BsSortDown, BsSortUp } from "react-icons/bs";
import { Form, Button } from "react-bootstrap";
import ResultatsService from "../services/ResultatsService";
import FileUploadService from "../services/FileUploadService";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import FormInputs from "./FormInputs";
import Moment from 'react-moment';
import { InfoMsg, gicon } from "../helpers";

const Docs = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]), folder = "docfiles", ctype = "cdtype";
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [idata, setData] = useState([]);
  const inpts = { ctitrefichier: ["Titre", "", 1, 1, {}], cdetailfichier: ["Description", "", 1, 1, {}], cdateupload: ["Date Mise en Ligne", "", undefined, 1, { Cell: (cellInfo) => <Moment format="DD/MM/YYYY HH:mm" date={cellInfo.row.original.cdateupload} />, props: { sortDescFirst: true, className: "center" } }], cnomfichier: ["Visualiser", "", undefined, 1, { Cell: (cellInfo) => <a href={localStorage.getItem("url" + folder) + cellInfo.row.original.cnomfichier} title={cellInfo.row.original.cnomfichier} onClick={() => cellInfo.updatefile(cellInfo.row.original.cid, { cdatedownload: '_dt' })} target="_blank" /*rel="noopener noreferrer"*/>{gicon(cellInfo.row.original.cnomfichier)}</a>, props: { className: "center", disableSortBy: true } }], cdatedownload: ["Date Dernier Téléchargement", "", undefined, 1, { Cell: (cellInfo) => cellInfo.row.original.cdatedownload && <Moment format="DD/MM/YYYY HH:mm" date={cellInfo.row.original.cdatedownload} />, props: { sortDescFirst: true, className: "center" } }] };
  const [inputs, setInputs] = useState(Object.fromEntries(Object.entries(inpts).map(([key, val]) => [key, val[1]])));
  const data = useMemo(() => idata, [idata]);
  const columns = useMemo(() => Object.entries(inpts).filter((e) => e[1][3]).map((e) => (Object.assign({ Header: e[1][0], accessor: e[0] }, e[1][4].Cell ? { Cell: e[1][4].Cell } : {}, e[1][4].props))), []);
  const [pages, setPages] = useState([0, 0]);

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const updatefile = (cid, body) => {
    FileUploadService.update(cid, body)
      .then((response) => {
        if (response.data.sets) {
          var irow = idata.findIndex((el) => el.cid == cid);
          //console.log("updatefile", cid, irow, idata);
          if (irow != -1) {
            var cdata = idata.slice(0);
            Object.keys(response.data.sets).map((val, idx) => cdata[irow][val] = response.data.sets[val]);
            setData(cdata);
          }
        }
      })
      .catch((err) => { });
  }

  /*const zipfile = () => {
    setProcessing(processing + "2");
    var files = [], filename = folder + "_" + (require('moment')().format('DDMMYYYY_HHmmss')) + ".zip";
    selectedFlatRows.map((val, idx) => files.push(val.original));
    FileUploadService.zip(folder, { filename, files })
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        initcto("Les fichiers ont été compressés et téléchargés avec succès.", "info", "2");
        document.body.removeChild(link);
      })
      .catch((err) => {
        initcto("Erreur serveur ou réseau lors de la compression des fichiers ! Veuillez réessayer.", "danger", "2");
      });
  }

  const Tcheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef(), resolvedRef = ref || defaultRef;
    useLayoutEffect(() => { resolvedRef.current.indeterminate = indeterminate; }, [resolvedRef, indeterminate]);
    return <input type="checkbox" ref={resolvedRef} {...rest} className="form-check-input" />;
  });*/

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, prepareRow, page, canPreviousPage, canNextPage, pageOptions, gotoPage, nextPage, previousPage, setPageSize, selectedFlatRows, state: { pageIndex, pageSize, sortBy } } = useTable({ columns, data, manualPagination: true, manualSortBy: true, autoResetPage: false, autoResetSortBy: false.valueOf, disableSortRemove: true, autoResetSelectedRows: false, pageCount: pages[0], updatefile/*, zipfile*/, initialState: { sortBy: [{ id: "cdateupload", desc: true }] /*pageSize: 5 //must add this value to pagesize <Form.Select array*/ }, getRowId: (row) => row.cid }, useSortBy, usePagination, useRowSelect/*, (hooks) => {
    hooks.visibleColumns.push(columns => [{ id: "selection", Header: ({ page, getToggleAllPageRowsSelectedProps }) => <Tcheckbox {...getToggleAllPageRowsSelectedProps()} title="Sélectionnez les fichiers à compresser" disabled={!page.length} />, Cell: ({ row }) => <Tcheckbox {...row.getToggleRowSelectedProps()} title="Sélectionnez les fichiers à compresser" />, Footer: (info) => <center><a className="cp textyelow" title="Créer un fichier zip" disabled={!info.selectedFlatRows.length} onClick={() => info.zipfile()}>{gicon("zip")}</a></center>, className: "center", disableSortBy: true }, ...columns])
  }*/);
  //Footer: (info) => { const total = useMemo(() => info.rows.reduce((sum, row) => (row.values.canneevague ? 1 : 0) + sum, 0), [info.rows]); return <>Total: {total}</>; }

  const getrapport = useCallback((params) => {
    setProcessing(processing + "1");
    ResultatsService.getRapport(folder, ctype, "?offset=" + (params.pageIndex * params.pageSize) + "&limit=" + params.pageSize + (params.ctitrefichier ? "&ctitrefichier=" + params.ctitrefichier : "") + (params.cdetailfichier ? "&cdetailfichier=" + params.cdetailfichier : "") + (params.sortBy.length ? "&order0=" + params.sortBy[0].id + "&order1=" + (params.sortBy[0].desc ? "DESC" : "ASC") : "")).then((response) => {
      if (response.data) {
        localStorage.setItem("url" + folder, response.data.url);
        setPages(response.data.pages);
        setData(response.data.afiles);
        initcto("", "", "1"/*, 1*/);
      }
    }).catch((err) => {
      initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données ! Veuillez réessayer.", "danger", "1");
    });
  }, []);

  const rapportg = () => {
    let lcols = columns.length/* + 1*/;
    //console.log("rapportg", data ? data.length : null, lcols, data);
    if (data && data.length) {
      let files = [];
      page.map((row, i) => {
        prepareRow(row);
        files.push(<tr {...row.getRowProps()}>{row.cells.map((cell) => <td {...cell.getCellProps()} className={cell.column.className}>{cell.render('Cell')}</td>)}</tr>);
      });
      return files;
    }
    else {
      return <tr><td colSpan={lcols} className="alert alert-warning center">Aucune donnée trouvée.</td></tr>;
    }
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
    if (p) { inpt.current[0].setHiden(true); }
  }

  useLayoutEffect(() => {
    //On_Mount & On_EachUpdate_InAnyElement_InDependencyArray
    getrapport({ ctitrefichier: inputs.ctitrefichier, cdetailfichier: inputs.cdetailfichier, sortBy, pageIndex, pageSize });
  }, [getrapport, inputs.ctitrefichier, inputs.cdetailfichier, sortBy, pageIndex, pageSize]);

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    loggeduser.current && loggeduser.current.cid ? <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["1", "-5"]} aproc={["2"]} cinit={[data && !data.length]} />
      <div className="mtb"><h2 className="dfaic"><BsBookHalf className='opl' />&nbsp; Docs</h2><hr /></div>
      <FormInputs ref={ref => inpt.current[0] = ref} setProcessing={setProcessing} initcto={initcto} setInputs={setInputs} setData={setData} changeAction={getrapport} inputs={inputs} inpts={inpts} folder={folder} scrn="v" processing={processing} />
      <fieldset className="dfac fdc mb-1 w100"><legend>Liste de fichiers</legend>
        <div className="dfac w100" style={{ justifyContent: "center", height: "100%" }}>
          <div className="scrlcont w100">
            <table {...getTableProps()} className="meds" style={{ marginBottom: 2 }}>
              <thead>{headerGroups.map((headerGroup) => <tr {...headerGroup.getHeaderGroupProps()}>{headerGroup.headers.map((column) => <th {...column.getHeaderProps(pageOptions.length ? column.getSortByToggleProps() : undefined)} className={column.className} title={column.disableSortBy ? "" : "Trier / Changer de tri"}>{column.render('Header')}{pageOptions.length && column.isSorted ? column.isSortedDesc ? <> &nbsp;<BsSortDown className='opl' /></> : <> &nbsp;<BsSortUp className='opl' /></> : ""}</th>)}</tr>)}</thead>
              <tbody {...getTableBodyProps()}>{rapportg()}</tbody>
              {/*<tfoot>{footerGroups.map(group => <tr {...group.getFooterGroupProps()}>{group.headers.map(column => <td {...column.getFooterProps()} className={column.className}>{column.render('Footer')}</td>)}</tr>)}<tr><td colSpan={columns.length + 1} className="alert alert-warning meds center">{processing.indexOf("1") != -1 ? "Chargement..." : "Affichage de " + page.length + " résultat(s) sur " + pages[1]}</td></tr></tfoot>*/}
            </table>
          </div>
          <div className="alert alert-warning center smlf w100 aicjcc">
            <Button className="smlp icn sdiv opl" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</Button>
            <Button className="smlp icn sdiv opl" onClick={() => previousPage()} disabled={!canPreviousPage}>{"<"}</Button>
            <Button className="smlp icn sdiv opl" onClick={() => nextPage()} disabled={!canNextPage}>{">"}</Button>
            <Button className="smlp icn sdiv opl" onClick={() => gotoPage(pages[0] - 1)} disabled={!canNextPage}>{">>"}</Button>
            <div className="mdiv dfac fdr"><div>Page</div><strong>{pageOptions.length ? pageIndex + 1 : 0} sur {pageOptions.length}</strong></div>
            <div className="mdiv dfac fdr"><div>Aller à Page :</div><Form.Control type="number" className="smlp smli meds icn" defaultValue={pageIndex + 1} min={1} max={100000} onChange={(e) => { const page = e.target.value ? Number(e.target.value) - 1 : 0; gotoPage(page); }} disabled={!pageOptions.length} /></div>
            <div className="mdiv dfac fdr"><div>Afficher</div><Form.Select className="smlp smli meds icn" value={pageSize} onChange={(e) => { setPageSize(Number(e.target.value)); }} disabled={!pageOptions.length}>{[10, 20, 30, 40, 50, 100].map((ps) => <option key={ps} value={ps}>{ps}</option>)}</Form.Select><div>par Page</div></div>
          </div>
        </div>
      </fieldset>
    </div> : <Navigate to={{ pathname: '/logout/3' }} />
  );
};

export default Docs;
