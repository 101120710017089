import http from "../http";

const getDraw = (folder, ctype, query) => {
  return http.get("/resultats/draw/" + folder + "/" + ctype + query);
};

const getRapport = (folder, ctype, query) => {
  return http.get("/resultats/rapport/" + folder + "/" + ctype + query);
};

export default {
  getDraw,
  getRapport
};
