import React, { useState, useLayoutEffect, useRef, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { BsSortDown, BsDownload } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import ClassementService from "../services/ClassementService";
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, BarChart, Bar, LabelList, Sector } from "recharts";
import FormInputs from "./FormInputs";
import { InfoMsg, ElipsText, exportChart } from "../helpers";

const Classement = (props) => {
  const ito = useRef(), loggeduser = useRef(), inpt = useRef([]), folder = "classfiles", ctype = "cctype", chrts = useRef([]);
  const [message, setMessage] = useState(["", ""]);
  const [processing, setProcessing] = useState("");
  const [data, setData] = useState();
  const [expl, setExpl] = useState([]);
  const inpts = { canneevague: ["Année *", "", 1, undefined, {}], ctypevague: ["Type Vague", "", 1, undefined, {}], cnomvague: ["Nom Vague *", "", 1, undefined, {}], ctheme: ["Critère Classement *", "", 1, undefined, {}], cfrequence: ["Fréquence *", "TOTAL", 1, undefined, { nof: "dn" }], cthematique: ["Thème *", "", 1, undefined, { alrt: 1 }], largg: ["Largeur", 600, 1, undefined, {}], auteg: ["Hauteur", 600, 1, undefined, {}], data: ["Données", undefined, undefined, {}] };
  const [inputs, setInputs] = useState(Object.fromEntries(Object.entries(inpts).map(([key, val]) => [key, val[1]])));

  let outi = localStorage.getItem('outi');
  loggeduser.current = outi ? JSON.parse(outi) : {};

  const explist = (idx, sval) => {
    let newArr = expl.slice(0); newArr[idx] = sval === undefined ? !newArr[idx] : sval;
    setExpl(newArr);
  }

  const getdraw = useCallback((params) => {
    var alrt = false, arr = Object.keys(inpts), arrlen = arr.length;
    for (let i = 0; i < arrlen; i++) { if (inpts[arr[i]][0].slice(-2) == " *" && !params[arr[i]]) { alrt = true; break; } }
    if (alrt) {
      if (localStorage.getItem("alrt")) {
        initcto("Veuillez sélectionner une valeur pour chacune des listes déroulantes marquées d'un '*' !", "danger");
        inpt.current[0].reinitg();
        localStorage.removeItem("alrt");
      }
      return;
    } else localStorage.removeItem("alrt");

    setProcessing(processing + "1");
    ClassementService.getDraw(folder, ctype, "?cthematique=" + (params.cthematique || "") + (params.canneevague ? "&canneevague=" + params.canneevague : "") + (params.ctypevague ? "&ctypevague=" + params.ctypevague : "") + (params.cnomvague ? "&cnomvague=" + params.cnomvague : "") + (params.ctheme ? "&ctheme=" + params.ctheme : "") + (params.cfrequence ? "&cfrequence=" + params.cfrequence : "")).then((response) => {
      if (response.data) {
        setData(response.data.aquests);
        initcto("", "", "1"/*, 1*/);
        setTimeout(() => document.querySelectorAll('.recharts-legend-item').forEach((li) => { if (li.querySelector('.recharts-legend-item-text').innerText == "") li.classList.add("dn"); }), 1000);
        setTimeout(() => document.querySelectorAll('.recharts-pie-label-text').forEach((li) => { if (li.querySelector('tspan').innerHTML == "") li.parentNode.classList.add("dn"); }), 3000);
      }
    }).catch((err) => {
      initcto(err.response.data ? err.response.data.message : "Erreur serveur ou réseau lors du chargement des données du graphique ! Veuillez réessayer.", "danger", "1");
    });
  }, []);

  const drawg = () => {
    if (data) {
      //console.log(data);
      if (data.length) {
        let charts = [], fnts = { fontSize: "calc(3px + 0.3vw + 0.3vh)" }, fntm = { fontSize: "calc(4px + 0.3vw + 0.3vh)", fontWeight: 450 }, fntl = { fontSize: "calc(5px + 0.3vw + 0.3vh)", fontWeight: "bold" }, larg2 = inputs.largg * 0.25, ttlexp = (val, idx, mrt, ttl) => <><foreignObject x={5} y={5} width="100%" height={mrt} className="recharts-fo"><div xmlns="http://www.w3.org/1999/xhtml" className="recharts-title1" style={fntl} title={ttl}>{ttl}</div><div xmlns="http://www.w3.org/1999/xhtml" className="recharts-title2" style={fntm}>{val.ctitle}</div></foreignObject><foreignObject data-html2canvas-ignore="true" x={expl[idx] ? 0 : "90%"} y={0} width={expl[idx] ? "100%" : "10%"} height={30}><div xmlns="http://www.w3.org/1999/xhtml" className="recharts-expdiv" tabIndex="-1" onClick={() => explist(idx)} onBlur={() => explist(idx, null)}><div xmlns="http://www.w3.org/1999/xhtml" className="recharts-expbtn cp" title="Exporter le graphique"><BsDownload /></div><br /><div xmlns="http://www.w3.org/1999/xhtml" className="expl" style={{ display: expl[idx] ? "inline-block" : "none" }}><div className="cp" onClick={() => exportChart(chrts.current[idx], 'svg', val.cid)}>Télécharger au format SVG</div><div className="cp" onClick={() => exportChart(chrts.current[idx], 'html', val.cid)}>Télécharger au format HTML</div><div className="cp" onClick={() => exportChart(chrts.current[idx], 'png', val.cid)}>Télécharger au format PNG</div><div className="cp" onClick={() => exportChart(chrts.current[idx], 'jpeg', val.cid)}>Télécharger au format JPG</div><div className="cp" onClick={() => exportChart(chrts.current[idx], 'pdf', val.cid)}>Télécharger au format PDF</div></div></div></foreignObject></>;

        data.map((val, idx) => {
          //console.log(val.aresps);
          let cdata = val.aresps, mrt = 66, ttl = val.cid + ' - ' + val.clabel, rval;
          if (!cdata.length) { cdata = [{ cid: 0, label: "Aucune donnée trouvée", fill: "rgba(255,255,255,.25)", value: 100 }]; rval = 100; }

          charts.push(<ResponsiveContainer key={idx + inputs.largg + inputs.auteg} width={inputs.largg * 100 / window.innerWidth + "%"} height={"undefined"} aspect={inputs.largg / inputs.auteg} debounce={1}><BarChart ref={ref => chrts.current[idx] = ref} width={inputs.largg} height={inputs.auteg} data={cdata} layout="vertical" margin={{ top: mrt, right: 20, bottom: 5, left: 15 }}><CartesianGrid horizontal={false} strokeDasharray='3 3' fill='#fff' /><XAxis type="number" domain={[0, 100]} tickLine={false} axisLine={{ stroke: 'rgba(0,0,0,.25)' }} tick={<ElipsText style={fnts} ext="%" />} interval={0}></XAxis><YAxis type="category" dataKey="label" tickLine={false} axisLine={{ stroke: 'rgba(0,0,0,.25)' }} tick={rval ? false : <ElipsText style={fnts} textAnchor="end" verticalAnchor="start" />} width={larg2} interval={0}></YAxis><Tooltip cursor={expl[idx] ? false : { fill: "rgba(0, 0, 0, .1)" }} content={({ active, payload, label }) => !expl[idx] && active && payload[0].value != 0.001 ? <div className="recharts-tooltip"><div>{label + (rval ? '' : ' : ' + payload[0].value + '%')}</div></div> : null} /><Bar dataKey="value" opacity={.9} fill="#0075c2" label={() => rval ? <text style={fntl} x="50%" y="50%" fill={val.fill} textAnchor="middle" dominantBaseline="central">{cdata[0].label}</text> : null}><LabelList position="insideRight" formatter={(value) => rval ? '' : value + '%'} style={fntm} fill="#fff" /></Bar>{ttlexp(val, idx, mrt, ttl)}</BarChart></ResponsiveContainer>);
        });
        return charts.length ? charts : <div className={"alert alert-danger meds center w100"}>Données insuffisantes.</div>;
      }
      else {
        return <div className="alert alert-warning meds center w100">Aucune donnée trouvée.</div>;
      }
    }
  }

  const initc = () => {
    setMessage(["", ""]);
  }

  const initcto = (m, t, a, p) => {
    setMessage([m, t]);
    if (m) { clearTimeout(ito.current); ito.current = setTimeout(initc, m.length / 15 * 1000); window.scrollTo(0, 0); }
    if (a) setProcessing(processing.replace(a, ""));
    if (p) { inpt.current[0].setHiden(true); }
  }

  useLayoutEffect(() => {
    //On_Mount & On_EachUpdate_InAnyElement_InDependencyArray
    getdraw({ canneevague: inputs.canneevague, ctypevague: inputs.ctypevague, cnomvague: inputs.cnomvague, ctheme: inputs.ctheme, cfrequence: inputs.cfrequence, cthematique: inputs.cthematique });
  }, [getdraw, inputs.canneevague, inputs.ctypevague, inputs.cnomvague, inputs.ctheme, inputs.cfrequence, inputs.cthematique]);

  useLayoutEffect(() => {
    //On_Mount

    //On_Unmount
    return () => { clearTimeout(ito.current); }
  }, []);

  return (
    loggeduser.current && loggeduser.current.cid ? <div className="dfac fdc">
      <InfoMsg processing={processing} message={message} initc={initc} ainit={["-5"]} aproc={["1"]} />
      <div className="mtb"><h2 className="dfaic"><BsSortDown className='opl' />&nbsp; Classement</h2><hr /></div>
      <FormInputs ref={ref => inpt.current[0] = ref} setProcessing={setProcessing} initcto={initcto} setInputs={setInputs} setData={setData} changeAction={getdraw} inputs={inputs} inpts={inpts} reinit="Dimension du graphique" folder={folder} scrn="v" processing={processing} />
      <fieldset className="dfac fdc mb-1 w100"><legend>Graphique</legend>
        {processing.indexOf("1") != -1 ? <div className={"alert alert-info meds"}><span><Spinner animation="border" size="md" /> &nbsp; Chargement...</span></div> : <div className="dfac w100" style={{ justifyContent: "center", height: "100%" }}>{drawg()}</div>}
      </fieldset>
    </div> : <Navigate to={{ pathname: '/logout/3' }} />
  );
};

export default Classement;
